<template>
    <v-container
        class="px-0">
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessWithoutReload  @close="closeSuccessModal" :api="this.api"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-else
            :items="data"
            :search="search"
            :headers="dataHeader">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    flat
                    class="text-h4 pt-4"
                    height="auto">
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row
                                class="mb-3">
                                <v-toolbar-title
                                    class="font-weight-bold text-h4 my-3">
                                    This is the title for the table
                                </v-toolbar-title>
                            </v-row>
                            <!-- <v-row>
                                <v-text-field
                                    v-model="search"
                                    dense
                                    outlined 
                                    label="Search">

                                </v-text-field>
                            </v-row> -->
                            <AFilter
                                :filterHeaders="dataHeader"/>
                        </v-col>
                    </v-row>
                </v-toolbar>

            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../components/common/ASuccessWithoutReload.vue';
import AFilter from '../../components/common/filter/AFilter.vue';
export default {
    components:{
    ASuccessWithoutReload,
    AFilter,
},
    computed: mapState({
    //
}),
    props:[
    //
    ],
    data: () => ({
        search:"",
        data:[
            {
                email:"irfanhafiz329@gmail.com",
                company_name:"Irfan Sdn Bhd",
            },
            {
                email:"sdsds@gmail.com",
                company_name:"INNN cdn Bhd",
            },
            {
                email:"hamizan@gmail.com",
                company_name:"Hamizan Sdn Bhd",
            }
        ],
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name'
            },
            {
                text:'Email',
                value:'email'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
        },
       
        // BOC : Create a new function to build the api and return it to the fetch function

        // EOC
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        closeSuccessModal() {
            this.api.isSuccesful = false;
        }
    }
}
</script>