<template>
    <v-container>
        <v-row>
            <v-col
                class="pr-0 pb-0"
                cols="6">
                <v-select
                    style="border-radius:6px 0px 0px 6px"
                    :items="localFilterHeaders"
                    dense 
                    outlined 
                    v-model="localSelectedFitlerHeaders"
                    @change="handleHeadersChange"
                    item-text="text"
                    item-value="value">
                </v-select>
            </v-col>
            <v-col
                class="pl-0 pb-0"
                cols="6">
                <v-select
                    v-if="isShowBoolean"
                    v-model="localSearch"
                    style="border-radius:0px 6px 6px 0px"
                    :items="localItemsOptions"
                    dense
                    item-text="text"
                    item-value="value" 
                    outlined>

                </v-select>
                <v-text-field
                    v-if="!isShowBoolean && isShowTextField"
                    style="border-radius:0px 6px 6px 0px"
                    v-model="localSearch"
                    dense 
                    outlined
                    label="Search">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row
            justify="end"
            style="margin-top:-20px;margin-bottom:10px">
            <v-col
                class="pt-0 mt-0"
                cols="auto">
                <v-btn
                    color="primary"
                    outlined
                    style="border-radius:4px 0px 0px 4px"
                    small
                    @click="refreshPage"
                    :class="hovered.button1?'active':''"
                    @mouseover="hovered.button1=true"
                    @mouseleave="hovered.button1=false">
                    Refresh
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    small
                    @mouseover="hovered.button2=true"
                    style="border-radius:0px"
                    @mouseleave="hovered.button2 = false"
                    @click="resetValue"
                    :class="hovered.button2?'active':''">
                    Reset Filter
                </v-btn>
                <v-btn
                    color="primary"
                    outlined
                    style="border-radius: 0px 4px 4px 0px;"
                    elevation="0"
                    small
                    :class="(hovered.button3)?'active':''+'py-0'"
                    @mouseover="hovered.button3 = true"
                    @mouseleave="hovered.button3=false"
                    @click="filterValue"
                    >
                    Filter
                </v-btn>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>

export default {
    props : [
        'filterHeaders','data',
    ],
    mounted() {
        this.fetch()
    },
    methods: {
        fetch() {
            let tempFilter = this.$_.clone(this.filterHeaders);
            tempFilter = tempFilter.map(item=> {
                if(item.value!=='index' && item.value !=='action') {
                    return item;
                }
            });
            this.localSelectedFitlerHeaders = "all"; 
            let filter = [
                {
                    text:'All',
                    value:'all',
                },
                ...tempFilter,
            ]
            this.localFilterHeaders = filter;
            this.localData = this.$_.clone(this.data);
        },
        filterValue() {
            try{
                let tempData = this.$_.clone(this.localData);
                if(this.localSelectedFitlerHeaders === 'all') {
                    tempData = tempData.filter(obj=>{
                        let isFound = false;
                        isFound = Object.values(obj).some(value=>{
                            if(value==null || value == undefined) {
                                return false;
                            }
                            if(String(value).toLowerCase().includes(this.localSearch.toLowerCase())) {
                                return true;
                            }
                            return false;
                           
                        });
                        if(isFound) {
                            return obj
                        }
                    });
                }
                else{
                    if(typeof this.localSearch === 'object') {
                        this.localSearch = this.localSearch.value;
                    }
                    tempData = tempData.filter(item=>{
                        if(item[this.localSelectedFitlerHeaders]!=null || item[this.localSelectedFitlerHeaders] !=undefined) {

                            return String(item[this.localSelectedFitlerHeaders]).toLowerCase().includes(String(this.localSearch).toLowerCase());
                        }
                    })
                }
                this.$emit("filter",tempData);
            }catch(error) {
                alert(error)
            }
            // this.$emit("filter",this.localSearch,this.localSelectedFitlerHeaders);
        },
        refreshPage() {
            // BOC : Called parents page to refresh the data
            this.$emit("refresh");
            // EOC
        },
        resetValue() {
            this.localSelectedFitlerHeaders="all";
            this.localSearch = "";
            this.isShowBoolean = false;
            this.isShowTextField = true;
            this.$emit('reset',this.localData);
        },
        handleHeadersChange() {
            try{
                this.localSearch = "";
                this.isShowBoolean = false;
                this.isShowTextField = true;

                let tempSelectedHeader = this.filterHeaders.filter(item=>item.value===this.localSelectedFitlerHeaders);
                if(typeof tempSelectedHeader[0].type !== undefined) {
                    if(tempSelectedHeader[0].type ==='boolean')  {
                        this.localItemsOptions =  this.$_.clone(tempSelectedHeader[0].options);
                        this.localSearch = this.localItemsOptions[0];
                        this.isShowBoolean = true;
                        this.isShowTextField = false;
                    }
                }
            }catch(exception) {
                alert(exception);
            }
        }
    },
    data:() => ( {
        localSelectedFitlerHeaders:null,
        localSearch:null,
        localFilterHeaders:null,
        filterHeaderDataType:null,
        isShowBoolean:false,
        isShowTextField:true,
        localData:null,
        localItemsOptions:null,
        hovered:{
            button1:false,
            button2:false,
            button3:false,
        },
    }),
}
</script>
<style
    scoped>
    .active {
        background-color:#00688F;
        font-style:bold;
        color: white!important;
    }
    v-btn {
        transition:all 0.3s ease
    }
</style>